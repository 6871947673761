
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ScreenDetail } from '@/types/screen'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'ScreenAdd',
  components: { UploadFile }
})
export default class extends Vue {
  private customerList = []
  private paramsList = []
  private objectFields = []
  private info: ScreenDetail = {
    screenName: '',
    screenCode: '',
    screenUrl: '',
    screenImg: '',
    customerCode: '',
    screenPosition: ''
  }

  private rules = {
    screenName: [
      { required: true, message: '请输入大屏名称', trigger: ['blur', 'change'] }
    ],
    screenCode: [
      { required: true, message: '请输入大屏编码', trigger: ['blur', 'change'] }
    ],
    screenUrl: [
      { required: true, message: '请输入访问地址', trigger: ['blur', 'change'] }
    ],
    screenImg: [
      { required: true, message: '请输上传缩略图', trigger: ['blur', 'change'] }
    ],
    screenPosition: [
      { required: true, message: '请选择展示位置', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  get screenId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getCustomerList()
    this.getParamsList()
    if (this.screenId) {
      this.getDetail()
    }
  }

  // 获取角色列表
  getCustomerList () {
    this.$axios.get(this.$apis.customer.customerList, {}).then((res) => {
      this.customerList = res.list || []
    })
  }

  getParamsList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'bigScreen'
    }).then(res => {
      this.paramsList = res.bigScreen || []
    })
  }

  // 大屏详情
  getDetail () {
    this.$axios.get(this.$apis.screen.selectCustomerScreenById, {
      id: this.screenId
    }).then((res) => {
      this.info = res
      this.objectFields = (res.objectFields && JSON.parse(res.objectFields)) || []
    })
  }

  onSuccess (file: { filePrefix: string; fileUrl: string }) {
    this.info.screenImg = file.filePrefix + file.fileUrl
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.addScreen()
      }
    })
  }

  // 用户详情
  addScreen () {
    this.submitShow = true
    this.handleParams()
    const url = this.screenId
      ? this.$apis.screen.updateCustomerScreen
      : this.$apis.screen.insertCustomerScreen
    this.$axios.post(url, this.info).then(() => {
      this.submitShow = false
      this.$message.success('保存成功')
      this.$router.push({ name: 'screenList' })
    }).finally(() => {
      this.submitShow = false
    })
  }

  handleParams () {
    const list: Array<{ key: string; value: string }> = []
    this.objectFields.forEach((item: { key: string; value: string }) => {
      if (item.key && item.value) {
        list.push(item)
      }
    })
    this.info.objectFields = JSON.stringify(list)
  }
}
